import Projects from 'js/components/projects/v2/Projects';
import { GetServerSideProps } from 'next';

export default function Page() {
  return <Projects />;
}

export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {},
  };
};
